import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPost = ({ pageContext }) => {
  const book = pageContext.book
  const section = pageContext.section
  const subsection = pageContext.subsection
  return (
    <Layout>
      <ul class="breadcrumb">
        <li>
          <a href={`/books/${book.id}`}>{book.name}</a>{" "}
          <span class="divider">/</span>
        </li>
        <li>
          <a href={`/books/${book.id}/sections/${section.id}`}>
            {section.name}
          </a>{" "}
          <span class="divider">/</span>
        </li>
        <li class="active">{subsection.name}</li>
      </ul>

      <h1>{subsection.name}</h1>

      <ol>
        {subsection.contents &&
          subsection.contents.map(content => {
            return (
              <li>
                <a
                  href={`/books/${book.id}/sections/${section.id}/subsections/${subsection.id}/${content.id}`}
                >
                  {content.name}
                </a>
              </li>
            )
          })}
      </ol>
    </Layout>
  )
}

export default BlogPost
